// 分析报告相关接口
import { instance } from '@/utils/http.js'

// 获取知识点曲线，以及知识点累计曲线
export const getKnowledgeLine = function (params) {
  return instance({
    url: '/api/v1/ddup/getKnowledgeLine',
    method: 'get',
    params
  })
}
// 获取考点曲线，以及考点累计曲线
export const getPointLine = function (params) {
  return instance({
    url: '/api/v1/ddup/getCheckPointLine',
    method: 'get',
    params
  })
}

//分析报告-首页-用户做卷次数-完成题目数-得分率
export const getStudentDetail = function () {
  return instance({
    url: '/api/v1/ddup/getStudentDetail',
    method: 'get',
  })
}

//获取模块做卷次数以及得分率
export const getModuleData = function (params) {
  return instance({
    url: '/api/v1/ddup/getModuleData',
    method: 'get',
    params
  })
}

//薄弱考点
export const getCheckPointWeak = function (params) {
  return instance({
    url: '/api/v1/ddup/getCheckPointWeak',
    method: 'get',
    params
  })
}
//题型训练次数与得分率
export const getQuestionTypeWeak = function (params) {
  return instance({
    url: '/api/v1/ddup/getQuestionTypeWeak',
    method: 'get',
    params
  })
}
//
//薄弱知识点
export const getKnowledgeWeak = function (params) {
  return instance({
    url: '/api/v1/ddup/getKnowledgeWeak',
    method: 'get',
    params
  })
}

// 各科总结
export const getUserSubjectComment = function (params) {
  return instance({
    url: '/api/v1/ddup/getUserSubjectComment',
    // url: '/api/v1/report/subject_comment',
    method: 'get',
    params
  })
}

// 导出学情报告文档
export const getStudyReport = function (params) {
  return instance({
    url: '/api/v1/user/study_report',
    method: 'post',
    // headers: {
    //   // application/msword 表示要处理为word格式
    //   // application/vnd.ms-excel 表示要处理为excel格式
    //   'Content-Type': 'application/json; charset=UTF-8',
    // },
    // responseType: 'blob',
    params
  })
}