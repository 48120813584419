<template>
  <div>
    <Index ref="IndexRef" />
  </div>
</template>

<script>
import Index from './index.vue'
export default {
  components: {
    Index
  }
}
</script>

<style>
</style>